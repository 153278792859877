<template>
  <LxpMobileHeader v-if="isMobile" title="글쓰기" >
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="exitWrite">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-download">
        <button class="kb-btn-text-silver" @click="clickSave">{{ lrnPostSn > 0 ? '수정' : '등록' }}</button>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-myclass-editor" @click="toggles.extra = false">
    <!-- main-content -->
    <div v-if="!isMobile" class="main-content">
      <!-- editor-container -->
      <div class="editor-container editor-component">
        <!-- editor-aside -->
        <div class="editor-aside">
          <div class="editor-aside-inner">
            <div v-if="categories.length > 0" class="aside-kb-form-category">
              <SortButtonSelect
                  v-if="categories.length > 0"
                  v-model="params.lrnBoardCateSn"
                  v-model:toggle="toggles.cate"
                  :options="categories"
                  title="전체"
                  sequence-key="lrnBoardCateSn"
                  name-key="cateNm"
                  :is-all="true"
                  :is-num="true"
              />
            </div>
            <div class="aside-kb-form-title">
              <input v-model.trim="params.title" type="text" class="kb-form-title-input" placeholder="제목 입력">
            </div>
            <div class="aside-kb-form-filelist">
              <div v-for="(attachment, index) in attachments" class="file-item" :class="{'mt-3': index > 0}" :key="index">
                <div class="item-row">
                  <div class="file-name"><span class="name">{{ attachment.fileNm}}</span></div>
                  <button class="kb-btn-delete" @click="removeAttachment(index)"><i class="icon-delete"></i></button>
                </div>
                <div class="item-row"><div class="byte">{{ convertToStorageBytes(attachment.fileSz) }}</div></div>
              </div>
            </div>
            <div class="aside-kb-form-file">
              <div class="kb-form-file">
                <input ref="attachFile" class="kb-form-file-input" type="file" id="inpFile" multiple @change="attachedFile" >
                <label for="inpFile" class="kb-form-file-label kb-btn kb-btn-light-gray"><span class="text">파일첨부</span></label>
              </div>
              <div class="kb-form-file-bottom"><span class="text">파일첨부는 최대 3개까지 가능합니다.<br>(100MB 이하)</span></div>
            </div>

<!--            <div class="aside-bottom-buttons">-->
<!--              <button v-if="lrnPostSn > 0" class="kb-btn kb-btn-dark" @click="clickDelete">-->
<!--                <span class="text">삭제</span>-->
<!--              </button>-->
<!--              <button class="kb-btn kb-btn-light-gray" @click="exitWrite">-->
<!--                <span class="text">나가기</span>-->
<!--              </button>-->
<!--              <button class="kb-btn kb-btn-primary" @click="clickSave">-->
<!--                <span class="text text-white">{{ lrnPostSn > 0 ? '수정' : '등록' }}</span>-->
<!--              </button>-->
<!--            </div>-->

            <div class="aside-bottom-buttons">
              <div class="button-row">
                <button class="kb-btn kb-btn-primary" @click="clickSave">
                  <span class="text text-white">{{ lrnPostSn > 0 ? '수정' : '등록' }}</span>
                </button>
              </div>
              <div class="button-row">
                <button class="kb-btn kb-btn-light-gray" @click="exitWrite">
                  <span class="text">나가기</span>
                </button>
                <button v-if="lrnPostSn > 0" class="kb-btn kb-btn-dark" @click="clickDelete">
                  <span class="text">삭제</span>
                </button>
              </div>
            </div>

          </div>
        </div>
        <!-- //editor-aside -->
        <!-- editor-body -->
        <div class="editor-content">
          <div class="editor-content-body">
            <div v-show="false" ref="pageEditor"></div>
            <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>
          </div>
          <div class="editor-content-footer">
            <div class="content-source"></div>
          </div>
        </div>
        <!-- //editor-body -->
      </div>
      <!-- //editor-container -->
    </div>
    <!-- //main-content -->
    <div v-else class="main-content main-component">
      <div class="board-editor-container">
        <div class="editor-body">

          <div v-if="categories.length > 0" class="editor-body-category">
            <div class="dropdown">
              <h3 class="dropdown-btn title" @click="toggles.cate = !toggles.cate">{{ currentCateNm }} <i class="icon-arrow"></i></h3>
            </div>
            <MobileLearnBoardCateTap
                v-if="categories.length > 0"
                v-model="params.lrnBoardCateSn"
                v-model:toggle="toggles.cate"
                :items="categories"
            />
          </div>

          <div class="editor-body-top">
            <input v-model.trim="params.title" type="text" class="kb-form-title-input" placeholder="제목 입력">
            <div v-if="lrnPostSn > 0" class="dropdown" :class="{'is-active': toggles.extra}" data-offset="header-right">
              <button class="dropdown-btn" @click.stop="toggles.extra = !toggles.extra"><i class="icon-more"></i></button>
              <div class="dropdown-target">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
                      <a href="javascript:" class="dropdown-option-link" @click="clickDelete"><span class="dropdown-option-text">삭제</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="editor-contents" >
            <!-- 에디터영역 -->
            <div class="content-body" style="background-color: var(--kb-white);">
              <div ref="pageEditor"></div>
            </div>
            <span style="color: #4C4E50">※ 첨부파일은 PC에서 업로드 가능합니다.</span>
            <div class="content-footer">
              <div class="footer-column footer-column-right">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {learnBoardWriteSetup} from '@/assets/js/modules/learn/learn-board-setup';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import MobileLearnBoardCateTap from '@/components/learn/mobile/board/MobileLearnBoardCateTap';

export default {
  name: 'LearnBoardWrite',
  components: {MobileLearnBoardCateTap, SortButtonSelect, LxpMobileHeader},
  setup: learnBoardWriteSetup
};
</script>
